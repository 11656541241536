<template>
  <div class="main">
    <div class="page-box">
      <div class="page-title">权限配置</div>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item class="breadcrumb-active"
          ><i class="fa fa-dashboard"></i>工作台</el-breadcrumb-item
        >
        <el-breadcrumb-item class="breadcrumb-active"
          >组织机构</el-breadcrumb-item
        >
        <el-breadcrumb-item>权限配置</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="card-box">
      <!-- 角色列表 -->
      <div class="c-left">
        <div class="title-box">
          <i class="el-icon-user"></i>
          <div class="name">角色列表</div>
        </div>
        <div class="role-list">
          <div
            :class="['role-tree', { 'role-active': active === item.Role_Id }]"
            v-for="item in data"
            :key="item.Role_Id"
            @click="getPermissions(item.Role_Id)"
          >
            {{ item.RoleName }}
          </div>
        </div>
      </div>
      <!-- 菜单权限 -->
      <div class="c-right">
        <div class="right-box">
          <div class="title-box">
            <i class="el-icon-folder-opened"></i>
            <div class="name">菜单权限</div>
          </div>

          <div class="buttons">
            <el-button
              class="save"
              @click="getPermissionTemplate"
              size="mini"
              type="primary"
              >选择策略</el-button
            >
            <el-button
              class="save"
              @click="saveStrategy"
              size="mini"
              type="primary"
              >保存策略</el-button
            >
            <el-button @click="save" class="save" size="mini" type="primary"
              >保存</el-button
            >
          </div>
        </div>
        <div class="menu-box">
          <el-tree
            :data="roleTree"
            :show-checkbox="false"
            style="padding: 15px"
            node-key="ID"
            :props="props"
            :default-expand-all="true"
            :expand-on-click-node="false"
            :empty-text="loading ? '加载中...' : '暂无数据'"
          >
            <template #default="{ data }">
              <div class="action-group">
                <div class="action-text" :style="{ width: 200 + 'px' }">
                  <el-checkbox
                    v-model="data.leftCk"
                    @change="allChange(data)"
                    >{{ data.ModuleName }}</el-checkbox
                  >
                </div>
                <div class="action-item">
                  <el-checkbox
                    v-for="(item, index) in data.Actions"
                    :key="index"
                    v-model="item.checked"
                    @change="actionChange(data, item.checked)"
                    >{{ item.OperationName }}</el-checkbox
                  >
                </div>
              </div>
            </template>
          </el-tree>
        </div>
      </div>
    </div>

    <el-dialog
      title="保存当前策略"
      :visible.sync="dialogVisible"
      width="600px"
      :before-close="handleClose"
      top="100px"
    >
      <div class="input-box">
        <div class="title">模板名称:</div>
        <el-input v-model="templateName" />
      </div>
      <div class="input-box">
        <div class="title">描述内容:</div>
        <el-input
          type="textarea"
          :rows="4"
          placeholder="项目描述"
          v-model="templateDescribe"
        />
      </div>

      <div class="dialog-footer">
        <el-button @click="handleClose" class="pop-close">取消</el-button
        ><el-button class="pop-save" @click="addPermissionTemplate"
          >保存</el-button
        >
      </div>
    </el-dialog>

    <el-dialog
      title="选择策略"
      :visible.sync="dialogVisible2"
      width="1000px"
      :before-close="handleClose2"
      top="100px"
    >
      <div class="card-box2">
        <div class="screen-box">
          <div class="screen-input-box">
            <div class="title-box">模板名称:</div>
            <el-input v-model="name" placeholder="请输入模板名称"></el-input>
          </div>
          <button class="btn-pre" @click="getPermissionTemplate(1)">
            <i class="fa fa-search"></i>查询
          </button>
        </div>
        <div class="table-box">
          <el-table
            :data="tableData"
            border
            height="250"
            tooltip-effect="light"
            :header-cell-style="{ color: '#f5f5f5', background: '#23527c' }"
          >
            <el-table-column label="#" width="55">
              <template slot-scope="scope">
                <el-checkbox></el-checkbox>
              </template>
            </el-table-column>
            <el-table-column prop="NameCHS" label="模板名称" width="150">
            </el-table-column>
            <el-table-column prop="Remark" label="描述内容" width="100">
            </el-table-column>
            <el-table-column prop="CreateTime" label="创建时间" width="100">
            </el-table-column>
            <el-table-column label="操作" width="130">
              <template slot-scope="scope">
                <i
                  class="fa ion-checkmark-round"
                  @click="getPermissionTemplateId(scope.row)"
                ></i>
                <i
                  class="fa fa-trash"
                  @click="deletePermissionTemplate(scope.row)"
                ></i>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            @size-change="getPermissionTemplate(1)"
            @current-change="getPermissionTemplate"
            :current-page.sync="page"
            :page-size.sync="size"
            :page-sizes="[10, 20, 50, 100]"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  getModuleList,
  getRoleAll,
  getPermissions,
  savePermissions,
  addPermissionTemplate,
  getPermissionTemplate,
  getPermissionTemplateId,
  deletePermissionTemplate,
} from "@/api/user";
import { recurrence } from "@/utils/recursion";
import { Tree } from "element-ui";
export default {
  data() {
    return {
      data: [
        {
          label: "测试管理员",
          children: [
            {
              label: "信息员",
              last: true,
            },
            {
              label: "查看员",
              last: true,
            },
          ],
        },
        {
          label: "超级管理员",
          children: [
            {
              label: "信息员",
              last: true,
            },
            {
              label: "查看员",
              last: true,
            },
          ],
        },
      ],
      defaultProps: {
        label: "ModuleName",
      },
      props: { children: "Children" },
      roleTree: null,
      active: null,
      newTree: null,
      dialogVisible: false,
      templateName: null,
      templateDescribe: null,
      dialogVisible2: false,
      name: null,
      tableData: [],
      page: 1,
      size: 10,
      total: null,
      loading: true,
    };
  },
  methods: {
    handleNodeClick(data) {
      if (data.last) {
      }
    },
    getRole(data, array) {
      data.forEach((item) => {
        if (item.leftCk) {
          array.push(item);
        } else {
          if (item.Actions.length !== 0) {
            const findIndex = item.Actions.findIndex((cItem) => {
              return cItem.checked;
            });
            if (findIndex !== -1) {
              array.push(item);
            }
          }
        }
        if (item.Children.length !== 0) {
          this.getRole(item.Children, array);
        }
      });
    },
    save() {
      if (!this.active) {
        this.$message.error("请先选中角色列表");
      }
      const roleList = [];
      this.getRole(this.roleTree, roleList);
      const Modules = [];
      roleList.forEach((item) => {
        const newItem = {
          Id: item.ID,
          Code: item.ModuleCode,
          NameCHS: item.ModuleName,
          Icon: item.Icon,
          Description: item.Description,
          TypeId: item.TypeId,
          Func: item.Func,
          Enabled: item.Enable,
          IsKeepAlive: item.IsKeepAlive,
          Url: item.Url,
          ParentId: item.ParentId,
          Operations: [],
        };

        if (item.Actions.length !== 0) {
          item.Actions.forEach((cItem) => {
            if (cItem.checked) {
              const dItem = {
                Id: cItem.ID,
                NameCHS: cItem.OperationName,
                NameEN: cItem.OperationNameEN,
                JsFunc: cItem.JsFunc,
                ServerFunc: cItem.ServerFunc,
              };
              newItem.Operations.push(dItem);
            }
          });
        }
        Modules.push(newItem);
      });

      const data = {
        RoleId: this.active,
        Modules: Modules,
      };

      savePermissions(data).then((res) => {
        if (res.status === 200) {
          this.$message.success("保存成功");
        }
      });
    },
    handleClose() {
      this.dialogVisible = false;
      this.templateName = null;
      this.templateDescribe = null;
    },
    handleClose2() {
      this.dialogVisible2 = false;
    },
    saveStrategy() {
      this.dialogVisible = true;
    },
    allChange(data) {
      data.Actions.forEach((item) => {
        item.checked = data.leftCk;
      });

      setTimeout(() => {
        this.traverseData();
      }, 1);

      if (!data.Children) {
        return;
      }
      this.setChildrenChecked(data, data.leftCk);
    },
    setChildrenChecked(data, ck) {
      data.Children.forEach((item) => {
        item.leftCk = ck;
        item.Actions.forEach((c) => {
          c.checked = ck;
        });
        if (item.Children) {
          this.setChildrenChecked(item, ck);
        }
      });
    },
    actionChange(data, ck) {
      ck =
        data.Actions.filter((x) => {
          return x.checked;
        }).length == data.Actions.length;
      data.leftCk = ck;
      this.traverseData();
      // this.roleTree = this.roleTree.concat();
    },
    // 遍历数据为总体赋值
    traverseData() {
      this.roleTree.forEach((item, index, array) => {
        let isTrue = true;
        if (item.Children.length !== 0) {
          for (let i = 0; i < item.Children.length; i++) {
            if (!item.Children[i].leftCk) {
              isTrue = false;
              break;
            }
          }
          if (!isTrue) {
            array[index].leftCk = false;
          } else {
            array[index].leftCk = true;
          }
        }
      });
    },
    getMenuTree() {
      getRoleAll().then((res) => {
        if (res.status === 200) {
          this.data = res.response;
        }
      });
    },
    getPermiss(data, id, OperationIds) {
      for (let i = 0; i < data.length; i++) {
        if (id === data[i].ID) {
          if (data[i].Actions.length !== 0) {
            data[i].Actions.forEach((cItem, index, array) => {
              if (OperationIds.indexOf(`${cItem.ID}`) !== -1) {
                array[index].checked = true;
              }
            });
            data[i].leftCk = data[i].Actions.every((dItem) => {
              return dItem.checked;
            });
          }
          break;
        } else if (data[i].Children.length !== 0) {
          this.getPermiss(data[i].Children, id, OperationIds);
        }
      }
    },
    getPermissions(id) {
      this.active = id;
      this.roleTree = JSON.parse(JSON.stringify(this.newTree));
      getPermissions({ id }).then((res) => {
        if (res.status === 200) {
          const response = res.response;
          response.forEach((item) => {
            const OperationIds = item.OperationIds.split(",");
            this.getPermiss(this.roleTree, item.ID, OperationIds);
          });
        }
      });
    },
    //获取菜单权限
    getModuleList() {
      this.loading = true;
      getModuleList().then((res) => {
        if (res.status === 200) {
          recurrence(res.response);
          this.roleTree = res.response;
          this.newTree = JSON.parse(JSON.stringify(res.response));
        }
        this.loading = false;
      });
    },
    //添加
    addPermissionTemplate() {
      const roleList = [];
      this.getRole(this.roleTree, roleList);
      const Modules = [];
      roleList.forEach((item) => {
        const newItem = {
          Id: item.ID,
          Code: item.ModuleCode,
          NameCHS: item.ModuleName,
          Icon: item.Icon,
          Description: item.Description,
          TypeId: item.TypeId,
          Func: item.Func,
          Enabled: item.Enable,
          IsKeepAlive: item.IsKeepAlive,
          Url: item.Url,
          ParentId: item.ParentId,
          Operations: [],
        };

        if (item.Actions.length !== 0) {
          item.Actions.forEach((cItem) => {
            if (cItem.checked) {
              const dItem = {
                Id: cItem.ID,
                NameCHS: cItem.OperationName,
                NameEN: cItem.OperationNameEN,
                JsFunc: cItem.JsFunc,
                ServerFunc: cItem.ServerFunc,
              };
              newItem.Operations.push(dItem);
            }
          });
        }
        Modules.push(newItem);
      });

      const data = {
        Id: null,
        NameCHS: this.templateName,
        TemplateContent: JSON.stringify(Modules),
        Description: this.templateDescribe,
        Enabled: true,
        OrderId: null,
      };
      addPermissionTemplate(data).then((res) => {
        if (res.status === 200) {
          this.$message.success("策略保存成功");
          this.handleClose();
        }
      });
    },
    // 获取列表
    getPermissionTemplate(type) {
      if (type) {
        this.page = type;
      }
      const data = {
        page: this.page,
        size: this.size,
        name: this.name,
      };
      getPermissionTemplate(data).then((res) => {
        if (res.status === 200) {
          this.tableData = res.response.data;
          this.total = res.response.dataCount;
          this.dialogVisible2 = true;
        }
      });
    },
    //获取详情
    getPermissionTemplateId(data) {
      this.roleTree = JSON.parse(JSON.stringify(this.newTree));
      getPermissionTemplateId({ id: data.Id }).then((res) => {
        if (res.status === 200) {
          this.handleClose2();
          const response = JSON.parse(res.response.TemplateContent);
          response.forEach((item, index, array) => {
            item.ID = item.Id;
            array[index] = item;
          });
          response.forEach((item) => {
            const OperationIds = [];
            item.Operations.forEach((cItem) => {
              OperationIds.push(cItem.Id.toString());
            });

            this.getPermiss(this.roleTree, item.ID, OperationIds);
          });
        }
      });
    },
    //删除
    deletePermissionTemplate(data) {
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        deletePermissionTemplate({ id: data.Id }).then((res) => {
          if (res.status === 200) {
            this.$message.success("删除成功");
            this.getPermissionTemplate();
          }
        });
      });
    },
  },
  created() {
    this.getMenuTree();
    this.getModuleList();
  },
};
</script>

<style lang="scss" scoped>
.main {
  width: 100%;
  height: 100%;
  padding: 24px;
  box-sizing: border-box;
  background-color: #f7f8fa;
  .page-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .page-title {
      font-size: 18px;
      color: #000000;
    }
    .breadcrumb-active {
      color: #303133;
      font-weight: bolder;
      cursor: pointer;
      .fa {
        margin-right: 5px;
      }
    }
  }
  .card-box {
    width: 100%;
    height: calc(100% - 48px);
    border-radius: 2px 2px 2px 2px;
    opacity: 1;
    margin-top: 24px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    .c-left {
      width: 222px;
      margin-right: 24px;
      background-color: #fff;
      height: 100%;
      flex-shrink: 0;
      .title-box {
        height: 40px;
        display: flex;
        align-items: center;
        padding: 0 10px;
        background-color: #f6faff;
        font-size: 14px;
        font-weight: bolder;
        i {
          margin-right: 10px;
        }
      }
      .role-list {
        padding-top: 10px;
        min-height: calc(100% - 40px);
      }
    }
    .c-right {
      width: 100%;
      background-color: #fff;
      height: 100%;

      .right-box {
        display: flex;
        justify-content: space-between;
        height: 40px;
        background-color: #f6faff;
        align-items: center;
        .title-box {
          height: 40px;
          display: flex;
          align-items: center;
          padding: 0 10px;
          font-size: 14px;
          font-weight: bolder;
          i {
            margin-right: 10px;
          }
        }
        .save {
          height: 30px;
          margin-right: 10px;
        }
      }
      .menu-box {
        min-height: calc(100% - 40px);
        .action-group {
          display: flex;
          // line-height: 32px;
          justify-content: center;
          align-items: center;
          label {
            float: left;
          }
          .action-text {
            line-height: 33px;
            label {
              margin-right: 5px;
            }
          }
        }
      }
    }
  }
}
.input-box {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  .title {
    width: 90px;
    flex-shrink: 0;
  }
}
.dialog-footer {
  display: flex;
  justify-content: center;
  align-items: center;
}
.card-box2 {
  width: 100%;
  background: #ffffff;
  border-radius: 2px 2px 2px 2px;
  opacity: 1;
  box-sizing: border-box;
  .screen-box {
    display: flex;
    align-items: center;
    position: relative;
    padding: 0 12px;
    .el-input {
      width: 150px;
    }
    .el-button {
      margin-left: 24px;
    }
    .senior {
      position: absolute;
      width: 100%;
      top: 40px;
      left: 0;
      background: #fefefe;
      border: 1px solid #eae8e8;
      border-top-width: 1px;
      border-top-style: solid;
      border-top-color: rgb(234, 232, 232);
      box-sizing: border-box;
      z-index: 999;
      padding: 0 24px 24px 24px;
      border-top: 0;
      box-shadow: 0 7px 18px -12px #bdc0bb;
      .from-boxs {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .from-box {
          margin-top: 24px;
          .name {
            margin-bottom: 10px;
          }
          .el-select {
            width: 150px;
          }
          .el-input {
            width: 150px;
          }
        }
      }
      .buttons {
        display: flex;
        justify-content: flex-end;
        margin-top: 24px;
      }
    }
    button {
      margin-left: 24px;
      border: none;
      padding: none;
      padding: 6px 12px;
      border-radius: 3px;
      font-size: 14px;
      color: #fff;
      cursor: pointer;
      .fa {
        color: #fff;
        margin: 0;
        margin-right: 5px;
      }
    }
    .btn-pre {
      background-color: #3f729b;
    }
    .btn-pre:hover {
      background-color: #305777;
    }
    .el-select {
      margin-right: 24px;
    }
    .input-box {
      display: flex;
      align-items: center;
      height: 34px;
      .title-box {
        padding: 0 12px;
        border: 1px solid #d2d6de;
        line-height: 32px;
        border-right: none;
        color: #555;
        font-size: 14px;
      }
    }
  }
  .table-box {
    width: 100%;
    .el-table {
      margin-top: 24px;
      max-height: 500px;
      box-sizing: border-box;
    }
    .el-pagination {
      margin-top: 24px;
    }
    .table-color {
      color: #0076ff;
    }
    .buttons {
      display: flex;
    }
  }
}
</style>
<style lang="scss" scoped>
::v-deep .el-table {
  width: 100%;
  .el-table__header-wrapper table,
  .el-table__body-wrapper table {
    width: 100% !important;
  }
  .el-table__body,
  .el-table__footer,
  .el-table__header {
    table-layout: auto;
    .yincang {
      width: 100px !important;
      min-width: 100px !important;
      max-width: 100px !important;
      .cell {
        width: 100%;
        box-sizing: border-box !important;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: inherit !important;
        white-space: nowrap !important;
      }
    }
  }
  .el-table__header {
    .yincang {
      width: 100px !important;
      min-width: 100px !important;
      max-width: 100px !important;
      .cell {
        width: 100%;
        box-sizing: border-box !important;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: inherit !important;
        white-space: nowrap !important;
      }
    }
  }
}
</style>

<style scoped>
.role-tree {
  height: 36px;
  line-height: 36px;
  margin-bottom: 1px;
  text-align: center;
  cursor: pointer;
  border-radius: 18px;
  transition: all 0.1s;
}
.el-table >>> th.el-table__cell > .cell {
  font-weight: bolder;
  font-size: 12px;
  text-align: center;
  font-family: "Microsoft YaHei";
}
.el-table >>> .el-table__placeholder {
  width: 23px;
}
.el-table >>> th.el-table__cell {
  padding: 6px 0;
}
.el-table >>> .el-table__cell {
  padding: 6px 0;
}
.el-table >>> .el-table__body .cell {
  text-align: center;
  font-size: 12px;
  color: #333;
}
.role-active {
  background-color: #0076ff;
  color: #fff;
}
.role-tree:hover {
  background-color: #0076ff;
  color: #fff;
}
.el-checkbox {
  margin-right: 10px;
}
.el-input >>> .el-input__inner {
  height: 34px;
  border-color: #d2d6de;
  border-radius: 0;
}
.card-box >>> .el-tree-node__content {
  height: auto;
}
.main >>> .el-dialog__body {
  padding: 20px;
  border-top: 1px solid #e6e6e6;
}
.table-box >>> .el-table__empty-block {
  width: 100% !important;
}
.fa {
  margin-right: 10px;
  color: #3c8dbc;
  cursor: pointer;
}
.fa:hover {
  color: #72afd2;
}
</style>
