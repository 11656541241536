export const recursion = (data, list, number, ids) => {
  if (number === list.length) {
    return ids;
  } else {
    const item = data.find((cItem) => {
      return cItem.TreeId === list[number];
    });
    const children = item.Children;
    ids.push({ id: item.Id, type: item.TreeNodeTypeId });
    number++;
    return recursion(children, list, number, ids);
  }
};

export const recurrence = (data) => {
  if (!data) {
    return;
  }
  if (data) {
    data.forEach((item, index, array) => {
      array[index].leftCk = false;
      if (item.Actions) {
        array[index].Actions.forEach((_, cIndex, cArray) => {
          cArray[cIndex].checked = false;
        });
      } else {
        array[index].Actions = [];
      }

      if (array[index].Children) {
        recurrence(array[index].Children);
      } else {
        array[index].Children = [];
      }
    });
  }
};

//递归
export const reproduce = (array, multipleSelection, boolen) => {
  for (let i in array) {
    var data = array[i];
    if (data.Children) {
      data.disabled = boolen;
      multipleSelection.forEach((item) => {
        if (item.ProjectID === data.ProjectID) {
          data.disabled = true;
        }
      });
      reproduce(data.Children, multipleSelection, data.disabled); //自己调用自己
    } else {
      data.disabled = boolen;
      multipleSelection.forEach((item) => {
        if (item.ProjectID === data.ProjectID) {
          data.disabled = true;
        }
      });
    }
  }
  return array;
};

//递归2

export const multipleSelection = (array, array2) => {
  for (let i in array) {
    var data = array[i];
    if (data.Children) {
      array2.push(data);
      reproduce(data.Children); //自己调用自己
    } else {
      array2.push(data);
    }
  }
  return array2;
};
